<template>
  <v-container
    fluid
    tag="section"
  >
    <v-row justify="center">
      <v-col
        cols="12"
      >
        <material-card
          color="primary"
          icon="mdi-account-outline"
        >
          <template #title>
            Картка адміністратора
          </template>

          <ButtonComeBack class="ml-3 mb-3" />

          <v-form>
            <v-container class="py-0">
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                    color="purple"
                    label="ПIБ"
                    v-model="user.full_name"
                  />
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field
                    color="purple"
                    label="Логiн"
                    v-model="user.login"
                  />
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field
                    color="purple"
                    label="Email"
                    v-model="user.email"
                  />
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field
                    color="purple"
                    label="Новий пароль"
                    v-model="newPassword"
                  />
                </v-col>

                <v-col cols="12" class="text-right" >
                  <v-btn color="primary" min-width="150" @click="saveUser">
                    Зберегти
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {
    HTTP
} from '../http-common';
import { mapGetters, mapMutations, mapActions } from 'vuex'

  export default { 
    name: 'UserProfileView',
    data() {
      return {
        user: {},
        userProjectsValue: [],
        userRolesValue: [],
        userAdministrationValue: [],
        userOrdersValue: [],
        newPassword: null,
        rules: {
          required: value => !!value || "Це поле обов'язкове.",
          min: (v) => {
              return v ? v.length >= 8 || 'Min 8 characters' : false
          }
        },
      }
    },
    computed: {
      ...mapGetters(['snackbar', 'projects', 'roles'])
    },
    methods: {
      ...mapMutations(['setSnackbar']),
      ...mapActions(['getProjects', 'getStatus']),
      getUser() {
        HTTP.post('/get-user', {
          'user_id': this.$route.params.id 
        })
          .then(({ data }) => {
            this.user = data.content || {};
            this.setProjects();
            this.setRoles();
            this.setModules();
          })
          .catch((error) => {
            this.setSnackbar({ type: 'error', text: error.message });
          })
      },
      saveUser() {
        const dataUser = {
          'email': this.user.email || null,
          'full_name': this.user.full_name || null,
          'login': this.user.login || null,
          'password': this.newPassword
        }

        HTTP.post('/create-user', {
          ...dataUser
        })
          .then(() => {
            this.setSnackbar({ type: 'success', text: 'Успiшно збережено' });
            this.$router.push('/users')
          })
          .catch((error) => {
            this.setSnackbar({ type: 'error', text: error.message });
          })
      },
      setProjects() {
        if (this.user.projects && this.user.projects.length) {
          this.userProjectsValue = this.user.projects;
        }
      },
      setRoles() {
        if (this.user.roles && this.user.roles.length) {
          this.userRolesValue = this.user.roles;
        }
      },
      setModules() {
        if (this.user.modules && this.user.modules.length) {
          this.user.modules.forEach(el => {
            if (el && el.name === 'Адміністрування') {
              this.userAdministrationValue = el.modules
            }
            if (el && el.name === 'Замовлення') {
              this.userOrdersValue = el.modules
            }
          });
        }
      }
    },
    mounted() {
      this.getProjects();
      this.getStatus();
    }
  }
</script>
